import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { ensure } from '../common';

export default function Header(props: any) {
  const [menuExpanded, setMenuExpanded] = React.useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!menuRef.current?.contains(ensure(e.target))) {
        setMenuExpanded(false);
      }
    };
    document.addEventListener('mousedown', listener);
    return () => document.removeEventListener('mousedown', listener);
  }, []);
  return (
    <PlasmicComponent
      component="Header"
      forceOriginal
      componentProps={{
        ...props,
        mobileNav: {
          ref: menuRef
        },

        showMobileMenu: menuExpanded,
        hamburgerMenu: {
          onClick: () => {
            setMenuExpanded(true);
          }
        }
      }}
    />
  );
}
