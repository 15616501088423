/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import DefaultPageTemplate from './src/templates/DefaultPageTemplate';
import * as React from 'react';

export const shouldUpdateScroll = function ({ routerProps }) {
  if ('scrollData' in (routerProps.location?.state || {})) {
    return false; // TabGroup will handle `scrollData`.
  } else {
    return true; // Use Gatsby default behavior.
  }
};

export const wrapPageElement = function ({ element, props }) {
  return <DefaultPageTemplate {...props}>{element}</DefaultPageTemplate>;
};
