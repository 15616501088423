import { ComponentRenderData, PlasmicRootProvider } from '@plasmicapp/loader-gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { DocsRating } from '../components/DocsRating';
import { Layout } from '../components/Layout';
import { PageInfo } from '../components/PageInfo';
import { GatsbyHeading } from '../components/PageTOC';
import { TabGroupsStateProvider } from '../components/TabGroups';
import { createLoader } from '../loader';
import { AppMDXProvider } from './AppMDXProvider';
import { AppPageProps } from './AppPageProps';

const DocsPageTemplateQuery = graphql`
  query DocsPageTemplateQuery {
    allMdx {
      nodes {
        fields {
          slug
        }
        headings {
          value
          depth
        }
      }
    }
    plasmicComponents(
      componentNames: [
        "Layout"
        "TocNode"
        "PageTocNode"
        "Tabs"
        "ValuePropSummary"
        "NavigationListItem"
        "QuickstartPage"
        "ExpandButton"
        "FeedbackPoll"
        "CardGroup"
        "CardDataIntegrations"
        "CardDeveloperQuickstart"
        "CardIntro"
        "CardNoCodeQuickstart"
        "CardPlasmicLevels"
        "Note"
      ]
    )
  }
`;

interface PageNode {
  fields: {
    slug: string;
  };
  headings: GatsbyHeading[];
}

interface DocsPageTemplateQueryData {
  allMdx: {
    nodes: PageNode[];
  };
  plasmicComponents: ComponentRenderData;
}

export function DocsPageTemplate(props: AppPageProps) {
  // In Gatsby V3 with gatsby-plugin-mdx, I'm pretty sure there's no way
  // to make a page query for the current MDX node in a template/layout.
  // Therefore, we're going to fetch ALL MDX nodes and find the node.
  // This is inefficient, but it's okay because the data is cached with SSR.
  const data = useStaticQuery<DocsPageTemplateQueryData>(DocsPageTemplateQuery);
  const pageNode = React.useMemo(
    () => getCurrentPageNode(props.location.pathname, data.allMdx),
    [props.location.pathname, data.allMdx]
  );

  return (
    <PageInfo.Provider value={{ location: props.location }}>
      <AppMDXProvider>
        <TabGroupsStateProvider>
          <PlasmicRootProvider loader={createLoader()} prefetchedData={data.plasmicComponents}>
            <Layout withSidebar headings={pageNode.headings.filter((heading) => heading.depth <= 3)}>
              <h1>{props.pageContext.frontmatter?.title}</h1>

              {props.children}

              <DocsRating path={props.location.pathname} />
              <p style={{ marginTop: 16 }}>
                Have feedback on this page? Let us know on our{' '}
                <a href="https://forum.plasmic.app/" target="_blank">
                  forum
                </a>
                .
              </p>
            </Layout>
          </PlasmicRootProvider>
        </TabGroupsStateProvider>
      </AppMDXProvider>
    </PageInfo.Provider>
  );
}

function getCurrentPageNode(pathname: string, allMdx: DocsPageTemplateQueryData['allMdx']): PageNode {
  // index pages are special, they should have a trailing /
  const slug = pathname.match(/^\/learn\/?$/)
    ? 'learn/'
    : // all other pages should be in the format "learn/section/page"
      pathname.replace(/(^\/|\/$)/g, '');
  const node = allMdx.nodes.find((node) => node.fields.slug === slug);
  if (!node) {
    throw new Error(`missing node with slug ${slug}`);
  }

  return node;
}
