import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import * as React from 'react';

export default function HitGroundRunningSection(props: any) {
  return (
    <PlasmicComponent
      component="HitGroundRunningSection"
      forceOriginal
      componentProps={{
        ...props,
        valueB2: {
          showcaseImage: (
            <video src="/videos/figma-import.mp4" preload="auto" loop muted playsInline autoPlay width="100%"></video>
          )
        }
      }}
    />
  );
}
