import * as path from 'path';
import * as url from 'url';

export function ensure<T>(x: T | undefined | null): T {
  if (!x) {
    throw new Error();
  }
  return x;
}

export function tuple<A, B>(a: A, b: B): [A, B] {
  return [a, b];
}

/**
 * Given a path, will check if it is a file
 * e.g. /docs/abc/ => false
 * e.g. /docs/abc => false
 * e.g. /docs/abc.png => true
 **/
export function isPathFile(p: string) {
  // e.g. /docs/abc/
  if (p.endsWith('/')) {
    return false;
  }

  // e.g. /docs/img.png => img.png
  const base = path.basename(p);
  // e.g. img.png => png
  const ext = path.extname(base);
  // if no extension, ext should be ""
  return !!ext;
}

/**
 * Checks if we need to add a trailing slash to a link
 **/
export function needsTrailingSlash(u?: string) {
  if (!u) {
    return false;
  }
  const myUrl = url.parse(u);
  // ignore if not a local URL (host is null if local URL)
  if (!!myUrl.host) {
    return false;
    // ignore if no path
  } else if (!myUrl.path) {
    return false;
    // ignore if it's a file
  } else if (isPathFile(myUrl.path)) {
    return false;
    // ignore if we already have a trailing slash
  } else if (myUrl.path.endsWith('/')) {
    return false;
  }
  return true;
}

export function addTrailingSlash(u: string): string {
  const myUrl = url.parse(u);
  myUrl.path += '/';
  myUrl.pathname += '/';
  return myUrl.format();
}

export function getPathname(u: string) {
  const myUrl = url.parse(u);
  return ensure(myUrl.pathname);
}

export function getHash(u: string) {
  const myUrl = url.parse(u);
  return myUrl.hash ?? '';
}

export function splitHash(h: string) {
  return h.replace('#', '').split(',');
}

export function removeTrailingSlash(path: string) {
  return path.replace(/\/$/, '');
}

export function ensureTrailingSlash(u: string): string {
  return needsTrailingSlash(u) ? addTrailingSlash(u) : u;
}
