import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import React from 'react';
import { pageTocMobileButton } from './PageTOC.module.css';

export interface GatsbyHeading {
  /** e.g. "My Heading" */
  value: string;
  /** Starts at 2 */
  depth: number;
}

export interface SluggedGatsbyHeading extends GatsbyHeading {
  slug: string;
}

export function PageTOC_Mobile({ sluggedHeadings }: { sluggedHeadings: SluggedGatsbyHeading[] }) {
  // Use <select> on mobile to provide a native user experience.
  return (
    <select
      className={pageTocMobileButton}
      value=""
      onChange={(event) => {
        event.preventDefault();
        event.stopPropagation();
        window.location.hash = `#${event.target.value}`;
      }}
    >
      <option value="" disabled selected>
        Jump to a section on this page…
      </option>
      {sluggedHeadings.map((heading) => {
        let indent = '';
        // Depth starts at 2 for h2, so a heading with depth `i` should have `i - 2` indent.
        for (let i = 0; i < heading.depth - 2; ++i) {
          indent += '- ';
        }
        return (
          <option key={heading.slug} value={heading.slug}>
            {indent}
            {heading.value}
          </option>
        );
      })}
    </select>
  );
}

export function PageTOC_Desktop({ sluggedHeadings }: { sluggedHeadings: SluggedGatsbyHeading[] }) {
  const validHeadings = React.useMemo(() => {
    const result = sluggedHeadings
      // PageTocNode currently only supports h2 and h3 variants.
      // If you want to add more headings like h4, you'll need to create a new h4 variant.
      .filter((heading) => heading.depth === 2 || heading.depth === 3);
    if (result.length < sluggedHeadings.length) {
      console.warn(
        'Unsupported heading depths found',
        sluggedHeadings.filter((heading) => heading.depth !== 2 && heading.depth !== 3)
      );
    }
    return result;
  }, [sluggedHeadings]);

  return validHeadings.map((heading) => {
    return (
      <PlasmicComponent
        key={heading.slug}
        component="PageTocNode"
        componentProps={{
          href: `#${heading.slug}`,
          children: heading.value,
          depth: `h${heading.depth}`
        }}
      />
    );
  });
}
