import { useLocation } from '@gatsbyjs/reach-router';
import { navigate } from 'gatsby';
import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { flattenChildren, flattenContent } from '../ReactUtil';
import { cyrb53 } from '../utils';

export interface TabGroup<TabKey extends string> {
  StateProvider: React.ComponentType;
  TabContainer: React.ComponentType;
  Tab: Record<TabKey, React.ComponentType>;
  setLocalStorage: (tabKey: TabKey) => void;
}

export interface TabMeta {
  /** Name visible in UI. */
  name: string;
  /** Name visible in the URL and local storage. */
  urlValue: string;
}

/** Scroll data used to recover the user's position in case TabContainers resize. */
interface ScrollData {
  /** ID of TabContainer to scroll to. */
  id: string;
  /** getBoundingClientRect().top of TabContainer to recover. */
  clientTop: number;
}

export interface TabGroupState<TabKey extends string> {
  groupTabKey: TabKey;
  setGroupTabKey(tabKey: TabKey, scrollData: ScrollData | null): void;
  scrollData: ScrollData | null;
}

/**
 * Creates a reusable TabGroup, where the active TabKey of its TabContainers are kept in sync via StateProvider.
 *
 * The source-of-truth for the active tab key is in the URL hash param.
 * If not present, fallback to local storage, and finally the first tab key of the meta object.
 *
 * Usage:
 *  ```tsx
 *  const FrameworkTabGroup = createTabGroup({
 *    Nextjs: { name: 'Next.js', urlValue: 'nextjs' },
 *    Gatsby: { name: 'Gatsby', urlValue: 'gatsby' },
 *    React: { name: 'React', urlValue: 'react },
 *  }, {
 *    urlKey: 'framework',
 *  })
 *
 *  <FrameworkTabGroup.StateProvider>
 *
 *    <h2>Company</h2>
 *    <FrameworkTabGroup.TabContainer>
 *      <FrameworkTabGroup.Tab.Nextjs>Vercel</FrameworkTabGroup.Tab.Nextjs>
 *      <FrameworkTabGroup.Tab.Gatsby>Gatsby</FrameworkTabGroup.Tab.Gatsby>
 *      <FrameworkTabGroup.Tab.React>Meta</FrameworkTabGroup.Tab.React>
 *    </FrameworkTabGroup.TabContainer>
 *
 *    <h2>Release Year</h2>
 *    <FrameworkTabGroup.TabContainer>
 *      <FrameworkTabGroup.Tab.Nextjs>2016</FrameworkTabGroup.Tab.Nextjs>
 *      <FrameworkTabGroup.Tab.Gatsby>2015</FrameworkTabGroup.Tab.Gatsby>
 *      <FrameworkTabGroup.Tab.React>2017</FrameworkTabGroup.Tab.React>
 *    </FrameworkTabGroup.TabContainer>
 *
 *  </FrameworkTabGroup.StateProvider>
 *  ```
 */
export function createTabGroup<TabKey extends string>(
  meta: Record<TabKey, TabMeta>,
  opts: {
    urlKey: string;
  }
): TabGroup<TabKey> {
  const firstTabKey = Object.keys(meta)[0] as TabKey;

  // Return value of TabGroup.Tab, enabling access to Tab.*.
  const tabComponentTypes = {} as Record<TabKey, React.ComponentType>;
  // Maps Tab component types (i.e. Tab.*) to their TabKey.
  const tabComponentTypeToKey = new Map<string | React.JSXElementConstructor<any>, TabKey>();
  // Maps URL keys to their TabKey.
  const tabUrlValueToKey = new Map<string, TabKey>();
  for (const entry of Object.entries(meta)) {
    const tabKey = entry[0] as TabKey;
    const tabMeta = entry[1] as TabMeta;
    const TabComponent: React.ComponentType = (props) => <Tab<TabKey> tabKey={tabKey} children={props.children} />;
    tabComponentTypes[tabKey] = TabComponent;
    tabComponentTypeToKey.set(TabComponent, tabKey);
    tabUrlValueToKey.set(tabMeta.urlValue, tabKey);
  }

  const urlValueToTabKey = (x: string | null | undefined) => (x ? tabUrlValueToKey.get(x) : undefined);
  const setLocalStorage = (tabKey: TabKey) => localStorage.setItem(opts.urlKey, tabKey);

  const TabGroupContext = React.createContext<TabGroupState<TabKey> | null>(null);
  const useTabGroupState = (): TabGroupState<TabKey> => {
    const state = useContext(TabGroupContext);
    if (!state) {
      throw new Error('missing TabsStateContext');
    }
    return state;
  };

  const TabsStateProvider: React.ComponentType = ({ children }) => {
    // Get active tab key from URL hash param
    const location = useLocation();
    const hashParams = new URLSearchParams(location.hash.replace(/^#/, ''));
    let groupTabKey = urlValueToTabKey(hashParams.get(opts.urlKey));
    if (!groupTabKey) {
      // Fallback to local storage (if browser)
      if (typeof localStorage !== 'undefined') {
        groupTabKey = urlValueToTabKey(localStorage.getItem(opts.urlKey));
      }

      if (!groupTabKey) {
        // Fallback to first tab key
        groupTabKey = firstTabKey;
      }
    }

    return (
      <TabGroupContext.Provider
        value={{
          groupTabKey,
          setGroupTabKey: (tabKey, scrollData) => {
            if (tabKey === groupTabKey) {
              return;
            }
            hashParams.set(opts.urlKey, meta[tabKey].urlValue);
            // Not sure why navigate is returning undefined after Gatsby 5 upgrade, so don't .catch
            navigate('#' + hashParams.toString(), { state: { scrollData } });
            localStorage.setItem(opts.urlKey, tabKey);
          },
          scrollData: (location.state as any)?.scrollData ?? null
        }}
      >
        {children}
      </TabGroupContext.Provider>
    );
  };

  const TabContainer: React.ComponentType = ({ children }) => {
    const { groupTabKey, setGroupTabKey, scrollData } = useTabGroupState();

    // Parse Tab.* in children with the following goals...
    const { id, tabs, activeTabKey } = useMemo(() => {
      // 1) Transform into useful format
      const tabs = flattenChildren(children).map((child) => {
        if (React.isValidElement(child)) {
          const tabKey = tabComponentTypeToKey.get(child.type);
          if (tabKey) {
            return {
              tabKey,
              name: meta[tabKey].name,
              content: child
            };
          } else {
            console.error(child);
            throw new Error(`illegal child ${child.type} nested under TabContainer`);
          }
        } else {
          console.error(child);
          throw new Error(`illegal child "${child}" nested under TabContainer`);
        }
      });

      // 2) Generate a unique ID for `scrollData`
      const id = cyrb53(tabs.map((tab) => flattenContent(tab.content)).join()).toString(36);

      // 3) Compute activeTabKey (might not be groupTabKey if tab doesn't exist)
      const activeTabKey = tabs.some((tab) => tab.tabKey === groupTabKey) ? groupTabKey : tabs[0].tabKey;

      return { id, tabs, activeTabKey };
    }, [children]);

    // ref is used to get the TabContainer's client bounds onClick.
    const ref = useRef<HTMLElement | null>(null);

    // onMount is called when the TabContainer is mounted/unmounted.
    const onMount = useCallback(
      (el: HTMLElement | null) => {
        ref.current = el;

        // Handle scrollData if it's for this TabContainer.
        if (el && scrollData && el.id === scrollData.id) {
          const { top } = el.getBoundingClientRect();
          window.scrollBy({
            top: top - scrollData.clientTop,
            // @ts-expect-error: "instant" still works https://github.com/w3c/csswg-drafts/issues/3497
            behavior: 'instant'
          });
        }
      },
      [scrollData]
    );

    return (
      <PlasmicComponent
        component="Tabs"
        componentProps={{
          ref: onMount,
          id,
          activeTabKey,
          tabButtons: tabs.map((tab) => {
            return (
              <PlasmicComponent
                key={tab.tabKey}
                component="Tabs.Button"
                componentProps={{
                  tabKey: tab.tabKey,
                  name: tab.name,
                  onClick: () => {
                    let scrollData = null;
                    if (ref.current) {
                      const { top } = ref.current.getBoundingClientRect();
                      scrollData = { id, clientTop: top };
                    }
                    setGroupTabKey(tab.tabKey, scrollData);
                  }
                }}
              />
            );
          }),
          tabContents: tabs.map((tab) => {
            return (
              <PlasmicComponent
                key={tab.tabKey}
                component="Tabs.Content"
                componentProps={{ tabKey: tab.tabKey, children: <div className="plasmic-reset">{tab.content}</div> }}
              />
            );
          })
        }}
      />
    );
  };

  return {
    StateProvider: TabsStateProvider,
    TabContainer,
    Tab: tabComponentTypes,
    setLocalStorage
  };
}

interface TabProps<TabKey extends string> {
  /** tabKey is only used by TabContainer. */
  tabKey: TabKey;
  children: React.ReactNode;
}

function Tab<Key extends string>({ children }: TabProps<Key>) {
  return <>{children}</>;
}
