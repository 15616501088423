import { PlasmicComponent } from '@plasmicapp/loader-react';
import * as React from 'react';

export default function NavigationListItem(props: any) {
  const { href } = props;
  let styles = props.styles;
  if (!styles && href) {
    styles = 'link';
    const curPath = globalThis?.location?.pathname;
    if (href === curPath || `${href}/` === curPath) {
      styles = 'active';
    }
  }
  return (
    <PlasmicComponent
      component="NavigationListItem"
      forceOriginal
      componentProps={{
        styles,
        indent: props.indent,
        args: {
          children: props.children
        },

        root: {
          as: href ? 'a' : 'div',
          props: {
            className: props.className,
            ...(href ? { href } : {})
          }
        }
      }}
    />
  );
}
