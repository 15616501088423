import { initPlasmicLoader } from '@plasmicapp/loader-gatsby';
import CommonLandingPage from './components/CommonLandingPage';
import Header from './components/Header';
import HitGroundRunningSection from './components/HitGroundRunningSection';
import NavigationListItem from './components/NavigationListItem';
import TeamMember from './components/TeamMember';
import config from './plasmic-config';

export const createLoader = () => {
  const loader = initPlasmicLoader(config);

  loader.substituteComponent(Header, 'Header');
  loader.substituteComponent(TeamMember, 'TeamMember');
  loader.substituteComponent(CommonLandingPage, 'CommonLandingPage');
  loader.substituteComponent(HitGroundRunningSection, 'HitGroundRunningSection');
  loader.substituteComponent(NavigationListItem, 'NavigationListItem');

  return loader;
};
