import '@docsearch/css/dist/button.css';
import '@docsearch/css/dist/modal.css';
import { DocSearch } from '@docsearch/react';
import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import React from 'react';
import './DocsHeader.css'; // custom DocSearch CSS variables are here

export default function DocsHeader() {
  return (
    <PlasmicComponent
      component="DocsHeader"
      componentProps={{
        // We use 2 Algolia DocSearch products: Crawler and Search
        //
        // Algolia Crawler parses our website and extracts content data to build an index.
        // We've configured it to automatically re-index every day at midnight UTC.
        // You can manually trigger a re-index in the console.
        // TODO: Automatically re-index after every deploy.
        // https://crawler.algolia.com/admin/crawlers/3a066d55-8f23-43d3-9574-93da78372098/overview
        //
        // Algolia Search uses the index to provide search results.
        // https://www.algolia.com/apps/OA31MKR0WO/dashboard
        search: <DocSearch appId="OA31MKR0WO" indexName="plasmic" apiKey="9d44fa21ebe1def80b9fdde7ec5d3aa8" />
      }}
    />
  );
}
