import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import * as React from 'react';
import LoopingVideo from './LoopingVideo';

export default function CommonLandingPage(props: any) {
  return (
    <PlasmicComponent
      forceOriginal
      component="CommonLandingPage"
      componentProps={{
        ...props,
        makeEditableImage: {
          render: (props: any) => <LoopingVideo src="/videos/multi-view-design.mp4" className={props.className} />
        }
      }}
    />
  );
}
