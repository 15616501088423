import { ComponentRenderData, PlasmicRootProvider } from '@plasmicapp/loader-gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import 'modern-normalize';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { applyFieldMapping, ClientHydrated } from '../components/CodeBlock';
import CopyButton from '../components/CopyButton';
import { Note } from '../components/Note';
import { noteTopRightButton } from '../components/Note.module.css';
import { PageInfo } from '../components/PageInfo';
import { Spacer } from '../components/Spacer';
import { TabGroupsStateProvider } from '../components/TabGroups';
import { createLoader } from '../loader';
import '../styles/app-content.css';
import '../styles/base.css';
import '../styles/main-content.css';
import { truncate } from '../styles/utility.module.css';
import { AppMDXProvider } from './AppMDXProvider';
import { AppPageProps } from './AppPageProps';

const codegenSpecificComponents = {
  // For some reason, internal links are still getting opened in the same tab.
  // This forces all links to be open in a new tab.
  a: (props: any) => <a target="_blank" {...props} />
} as const;

const CodegenPlasmicComponentsData = graphql`
  query CodegenPlasmicComponentsData {
    plasmicComponents(componentNames: ["ExpandButton", "Tabs", "Note"])
  }
`;

type CodegenPageProps = AppPageProps & { withoutApiToken?: boolean };

/**
 * This page template is embedded as an iframe in Plasmic Studio,
 * e.g. https://docs.plasmic.app/app-content/codegen/
 *
 * Plasmic Studio includes a padding around the iframe, so the page should not include any padding itself.
 */
export function AppContentPageTemplate(props: CodegenPageProps) {
  const plasmicComponentsData = useStaticQuery<{ plasmicComponents: ComponentRenderData }>(
    CodegenPlasmicComponentsData
  );

  const [isClient, setClient] = useState(false);

  useEffect(() => {
    if (!isClient) {
      setClient(true);
    }
  });

  let showNoComponentsWarning = false;
  if (isClient) {
    const hashArgs = new URLSearchParams(window.location.hash.replace(/^#/, ''));
    showNoComponentsWarning = hashArgs.get('noComponents') === 'true';
  }

  const apiToken = applyFieldMapping('APITOKEN', isClient);

  return (
    <PageInfo.Provider value={{ location: props.location }}>
      <ClientHydrated.Provider value={isClient}>
        <Helmet>
          <base target={'_blank'} />
        </Helmet>
        <AppMDXProvider components={codegenSpecificComponents}>
          <TabGroupsStateProvider>
            <PlasmicRootProvider loader={createLoader()} prefetchedData={plasmicComponentsData.plasmicComponents}>
              <div
                key={isClient ? 'client' : 'server'}
                className="main-content app-content"
                style={{
                  display: isClient ? 'block' : 'none',
                  width: '100%',
                  minHeight: '100%',
                  padding: '16px 32px'
                }}
              >
                {showNoComponentsWarning && (
                  <>
                    <Note type="warning" title="Warning">
                      This project has no pages or components, so there is nothing to load! Please create some pages or
                      components first.
                    </Note>
                    <Spacer y="1rem" />
                  </>
                )}
                {!props.withoutApiToken && apiToken && (
                  <ClientHydrated.Consumer>
                    {() => (
                      <Note type="blue">
                        <p className={truncate} style={{ margin: 0, paddingRight: 60 }}>
                          This project's public API token: {apiToken}
                        </p>
                        <CopyButton className={noteTopRightButton} text={apiToken} />
                      </Note>
                    )}
                  </ClientHydrated.Consumer>
                )}
                {props.children}
              </div>
            </PlasmicRootProvider>
          </TabGroupsStateProvider>
        </AppMDXProvider>
      </ClientHydrated.Provider>
    </PageInfo.Provider>
  );
}

export function AppContentPageTemplateWithoutApiToken(props: CodegenPageProps) {
  return <AppContentPageTemplate {...props} withoutApiToken />;
}
