import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import * as React from 'react';

interface TeamMemberProps {
  twitter: string;
  github: string;
  linkedin: string;
}

export default function TeamMember(props: TeamMemberProps) {
  return (
    <PlasmicComponent
      component="TeamMember"
      forceOriginal
      componentProps={{
        ...props,
        twitterLink: {
          href: `https://twitter.com/${props.twitter}`,
          target: '_blank'
        },

        githubLink: {
          wrap: (node) => (props.github ? node : null),
          props: {
            href: `https://github.com/${props.github}`,
            target: '_blank'
          }
        },

        linkedinLink: {
          href: props.linkedin,
          target: '_blank'
        }
      }}
    />
  );
}
