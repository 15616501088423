import { ComponentRenderData, PlasmicRootProvider } from '@plasmicapp/loader-gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Layout } from '../components/Layout';
import { createLoader } from '../loader';
import { AppMDXProvider } from './AppMDXProvider';
import { AppPageProps } from './AppPageProps';

/** Used like a Gatsby page template.  */
export function PlainPageTemplate(props: AppPageProps) {
  const title = props.pageContext.frontmatter?.title;
  const data = useStaticQuery<{ plasmicComponents: ComponentRenderData }>(graphql`
    query PlainPageTemplateQuery {
      plasmicComponents(componentNames: ["Layout"])
    }
  `);

  return (
    <AppMDXProvider>
      <PlasmicRootProvider loader={createLoader()} prefetchedData={data.plasmicComponents}>
        <Layout>
          {title && <h1>{title}</h1>}

          {props.children}
        </Layout>
      </PlasmicRootProvider>
    </AppMDXProvider>
  );
}
