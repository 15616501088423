import * as React from 'react';
import reactKeyedFlattenChildren from 'react-keyed-flatten-children';

/** Returns children as an array for introspection or filtering. */
export function flattenChildren(node: React.ReactNode) {
  return reactKeyedFlattenChildren(node) as (React.ReactElement<{ mdxType: string }> | React.ReactText)[];
}

/** Transforms a React child into a flat, concatenated string consisting of all its content, recursively. */
export function flattenContent(child: React.ReactChild): string {
  if (React.isValidElement(child)) {
    return flattenChildren(child.props.children).map(flattenContent).join();
  } else {
    return child.toString();
  }
}
