import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import GithubSlugger from 'github-slugger';
import 'modern-normalize';
import * as React from 'react';
import DocsHeader from '../components/DocsHeader';
import '../styles/base.css';
import '../styles/main-content.css';
import { GatsbyHeading, PageTOC_Desktop, PageTOC_Mobile, SluggedGatsbyHeading } from './PageTOC';
import { Sidebar } from './Sidebar';

export const Layout = ({
  withSidebar,
  headings,
  children
}: {
  withSidebar?: boolean;
  headings?: GatsbyHeading[];
  children: React.ReactNode;
}) => {
  const sluggedHeadings = React.useMemo(() => {
    if (!headings) {
      return undefined;
    }

    // GithubSlugger keeps state of which slugs have already been used,
    // so it's important to create a new instance for each set of slugs.
    const slugger = new GithubSlugger();
    return headings.map<SluggedGatsbyHeading>((heading) => ({ ...heading, slug: slugger.slug(heading.value) }));
  }, [headings]);

  return (
    <PlasmicComponent
      component="Layout"
      componentProps={{
        header: <DocsHeader />,
        sidebar: withSidebar ? ( // div wrapper is for avoiding styles from Plasmic flex child styles
          <div>
            <Sidebar />
          </div>
        ) : null,
        withPageToc: sluggedHeadings && sluggedHeadings.length > 0,
        pageTocDesktop: sluggedHeadings && <PageTOC_Desktop sluggedHeadings={sluggedHeadings} />,
        pageTocMobile: sluggedHeadings && <PageTOC_Mobile sluggedHeadings={sluggedHeadings} />,
        children: (
          // Plasmic's `root_reset_projectid` class sets `white-space: pre-wrap`,
          // which messes up the HTML generated from MDX. So we reset it to `initial` here.
          <main className="main-content DocSearch-content plasmic-reset" style={{ width: '100%' }}>
            {children}
          </main>
        )
      }}
    />
  );
};
