type Properties = Record<string, any>;

const global = globalThis as {
  amplitude?: {
    track(name: string, properties?: Properties): void;
  };
  posthog?: {
    capture(name: string, properties?: Properties): void;
  };
};

export function track(name: string, properties?: Properties) {
  global.amplitude?.track(name, properties);
  global.posthog?.capture(name, properties);
}
