import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppPageProps } from './AppPageProps';

/** Sets the <head> element for metadata and global initialization. */
export function Head({ pageProps }: { pageProps: AppPageProps }) {
  useEffect(() => {
    // GLightbox does not work in SSR mode. The workaround is to download its JS and CSS files via CDN in html.js.
    // https://www.gatsbyjs.com/docs/using-client-side-only-packages/#workaround-2-add-client-side-package-via-cdn
    // eslint-disable-next-line
    // @ts-ignore
    new GLightbox({
      // AppMDXProvider adds the .glightbox class for all remote images.
      // Local images processed by `gatsby-remark-images` don't get overridden
      // by AppMDXProvider, so tell Glightbox to use .gatsby-resp-image-image as well.
      selector: '.glightbox, .gatsby-resp-image-image'
    });
  }, [pageProps.location.pathname]); // Reinitialize Glightbox when the path changes

  const data = useStaticQuery<HeadQueryData>(HeadQuery);

  const frontmatterTitle = pageProps.pageContext.frontmatter?.title;
  const title = frontmatterTitle
    ? `${frontmatterTitle} | ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title;

  return (
    <Helmet
      title={title}
      meta={[
        { name: 'description', content: data.site.siteMetadata.description },
        { name: 'keywords', content: data.site.siteMetadata.keywords },
        // Open Graph tags (https://ogp.me/)
        { name: 'og:title', content: title },
        { name: 'og:type', content: 'website' },
        { name: 'og:url', content: data.site.siteMetadata.siteUrl + pageProps.location.pathname },
        { name: 'og:description', content: data.site.siteMetadata.description }
      ]}
    />
  );
}

const HeadQuery = graphql`
  query HeadQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
      }
    }
  }
`;

interface HeadQueryData {
  site: {
    siteMetadata: {
      siteUrl: string;
      title: string;
      description: string;
      keywords: string;
    };
  };
}
