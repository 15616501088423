import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import * as React from 'react';
import { track } from '../track';

export function DocsRating(props: { path: string }) {
  const { path } = props;

  const stripped = path.replace(/\/+$/, '');
  const filename = stripped === '/learn' ? '/learn/index' : stripped;

  const [haveVoted, setHaveVoted] = React.useState(false);
  const giveFeedback = (value: number) => {
    track('DocsRating', { page: filename, value: value });
    setHaveVoted(true);
  };

  return (
    <div style={{ marginTop: '64px' }}>
      {haveVoted ? (
        'Thanks for letting us know!'
      ) : (
        <PlasmicComponent
          component="FeedbackPoll"
          forceOriginal
          componentProps={{
            yesButton: {
              onClick: () => giveFeedback(1)
            },
            noButton: {
              onClick: () => giveFeedback(0)
            }
          }}
        />
      )}
    </div>
  );
}
