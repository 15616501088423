import React from 'react';
import { copyTextToClipboard } from '../utils';

const CopyButton = ({ text, className }: { text: string; className: string }) => {
  const [msg, setMsg] = React.useState<string>('Copy');
  const handleClick = () => {
    copyTextToClipboard(text);
    setMsg('Copied!');
    setTimeout(() => {
      setMsg('Copy');
    }, 2500);
  };
  return (
    <div className={className} onClick={handleClick}>
      {msg}
    </div>
  );
};

export default CopyButton;
