import classNames from 'classnames';
import React, { useState } from 'react';
import {
  sidebarContainer,
  sidebarContainerButton,
  sidebarContainerClose,
  sidebarContainerContentWrapper,
  sidebarContainerOpen,
  sidebarContainerScrollContainer
} from './SidebarContainer.module.css';

export function SidebarContainer(props: { children?: React.ReactNode }) {
  const { children } = props;
  const [show, setShow] = useState(false);
  return (
    <nav className={classNames(sidebarContainer, show ? sidebarContainerOpen : sidebarContainerClose)}>
      <button className={sidebarContainerButton} type={'button'} onClick={() => setShow(!show)}>
        {show ? '‹' : '›'}
      </button>
      <div className={sidebarContainerScrollContainer}>
        <ul className={sidebarContainerContentWrapper}>{children}</ul>
      </div>
    </nav>
  );
}
