import { MDXProvider } from '@mdx-js/react';
import { Components } from '@mdx-js/react/lib';
import React from 'react';
import { MdxCodeBlock } from '../components/CodeBlock';
import { Comment } from '../components/Comment';
import If from '../components/If';
import LoopingVideo from '../components/LoopingVideo';
import { Note } from '../components/Note';
import { YoutubeEmbed } from '../components/YoutubeEmbed';
import { addTrailingSlash, needsTrailingSlash } from '../common';

const defaultComponents = {
  a: ({ href, rel, referrerPolicy, ...props }: any) => {
    // Note this RegExp is copied from the `ButtonLink` component
    // of the Plasmic project "[PlasmicKit] Plasmic Website".
    if (href.match(/^https:\/\/(\w+\.)*plasmic\.app\//)) {
      referrerPolicy = 'unsafe-url'; // sends full path in Referer header
      rel = undefined; // unset, default rel was "noopener noreferrer"
    }
    if (needsTrailingSlash(href)) {
      href = addTrailingSlash(href);
    }
    return <a {...props} href={href} rel={rel} referrerPolicy={referrerPolicy} />;
  },
  img: (props: any) => {
    // Note this only works for remote images.
    // Local images processed by `gatsby-remark-images` won't work.
    return (
      <a href={props.src} className="glightbox">
        <img {...props} />
      </a>
    );
  },
  pre: MdxCodeBlock,
  Comment,
  If,
  LoopingVideo,
  Note,
  YoutubeEmbed
} as Components;

export function AppMDXProvider({ components, children }: { components?: Components; children: React.ReactNode }) {
  return (
    <MDXProvider components={{ ...defaultComponents, ...components }} disableParentContext>
      {children}
    </MDXProvider>
  );
}
