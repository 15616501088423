import * as React from 'react';

export default function LoopingVideo({ src, className }: { src: string; className?: string }) {
  return (
    <video controls loop muted playsInline autoPlay className={className}>
      <source src={src} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
}
