import { InitOptions } from '@plasmicapp/loader-react';

export default {
  projects: [
    {
      id: '87B5cHmB8bkBpBLAwwyNDA',
      token: 'LPr6Z3IOTm79Us0fVu4HLpraE3CvPmMl7Yat8u9sSDriQJe6BgaH9HNUy0R5Sv85pDlqLx1hXGAZ4oDSaE0g'
    }
  ],
  preview: process.env.NODE_ENV === 'development'
} as InitOptions;
