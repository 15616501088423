import { createContext } from 'react';

export type PageInfo = {
  location: {
    pathname: string;
    hash: string;
  };
};

export const PageInfo = createContext<PageInfo | undefined>(undefined);
