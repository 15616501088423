import { omit } from 'lodash';
import Highlight, { defaultProps, Language } from 'prism-react-renderer';
import Prism from 'prism-react-renderer/prism';
import React, { createContext, useContext } from 'react';
import './CodeBlock.css';
import CopyButton from './CopyButton';

// prism-react-renderer does not support all Prism languages out of box.
// (based on https://github.com/FormidableLabs/prism-react-renderer#faq)
((typeof global !== 'undefined' ? global : window) as any).Prism = Prism;
require('prismjs/components/prism-php');

export const ClientHydrated = createContext<boolean>(false);

// There's also `pathOrComponent`, but we stick to componentName instead of that
// for COMPONENT_OR_PAGEROUTE, because if this is your first time learning
// Plasmic, it's more confusing/cryptic to see <PlasmicComponent component='/'/>
// than <PlasmicComponent componentName='Homepage'/>.
const fieldMapping = {
  PROJECTID: 'projectId',
  APITOKEN: 'apiToken',
  COMPONENT_OR_PAGEROUTE: 'componentName',
  COMPONENTNAME: 'componentName',
  APP_HOST_URL: 'appHostUrl'
};

const highlightClassName = 'cb-highlight-code-line';

export function CodeBlock(props: { language: Language; children: string; fileName?: string }) {
  const { language, children, fileName } = props;
  const rawCode = Array.isArray(children) ? children[0] : children;
  const clientHydrated = useContext(ClientHydrated);
  const code = applyFieldMapping(rawCode, clientHydrated);
  return (
    <Highlight {...defaultProps} language={language} code={code.trim()}>
      {({ className, tokens, getLineProps, getTokenProps }) => {
        let rawLines = code.trim().split(/\n/g);
        if (tokens.length !== rawLines.length) {
          throw new Error(`Expecting ${tokens.length} === ${rawLines.length}`);
        }
        function makeLineHighlightInfo(): Array<'skip' | 'highlight' | 'normal'> {
          let isHighlightingBlock = false,
            isHighlightingNextLine = false;
          return rawLines.map((line) => {
            const simplifiedLine = line.replace(/\s/g, '');
            if (simplifiedLine.endsWith('highlight-start')) {
              isHighlightingBlock = true;
              return 'skip';
            } else if (simplifiedLine.endsWith('highlight-next-line')) {
              isHighlightingNextLine = true;
              return 'skip';
            } else if (simplifiedLine.endsWith('highlight-end')) {
              isHighlightingBlock = false;
              return 'skip';
            } else if (isHighlightingBlock) {
              return 'highlight';
            } else if (isHighlightingNextLine) {
              isHighlightingNextLine = false;
              return 'highlight';
            } else {
              return 'normal';
            }
          });
        }
        const lineHighlightInfo = makeLineHighlightInfo();
        return (
          <div className="cb">
            {fileName && <div className="cb-file-name">{fileName}</div>}
            <CopyButton
              className="cb-copy-button"
              text={rawLines.filter((line, i) => lineHighlightInfo[i] !== 'skip').join('\n')}
            />
            <div className="cb-scroll-container">
              <pre className={`${className}`}>
                {tokens.map((line, i) => {
                  const lineProps = getLineProps({ line, key: i });
                  if (lineHighlightInfo[i] === 'skip') {
                    return null;
                  }
                  if (lineHighlightInfo[i] === 'highlight') {
                    lineProps.className = `${lineProps.className} ${highlightClassName}`;
                  }
                  return (
                    <div key={i} {...omit(lineProps, 'style')}>
                      {line.map((token, key) => (
                        <span key={key} {...omit(getTokenProps({ token, key }), 'style')} />
                      ))}
                    </div>
                  );
                })}
              </pre>
            </div>
          </div>
        );
      }}
    </Highlight>
  );
}

export function applyFieldMapping(text: string, clientHydrated: boolean) {
  if (!clientHydrated) {
    return text;
  }
  const hashArgs = new URLSearchParams(window.location.hash.replace(/^#/, ''));
  for (const [toReplace, hashKey] of Object.entries(fieldMapping)) {
    const hashValue = hashArgs.get(hashKey);
    if (hashValue) {
      text = text.replace(new RegExp(toReplace, 'g'), hashValue);
    }
  }
  return text;
}

export function MdxCodeBlock({
  children: codeElement
}: {
  children: React.ReactElement<{
    children: string;
    className?: string;
    fileName?: string;
  }>;
}) {
  const { children, className, fileName } = codeElement.props;
  const language = (className ?? '').split(' ')[0].replace(/language-/, '');
  return (
    <CodeBlock language={language as Language} fileName={fileName}>
      {children}
    </CodeBlock>
  );
}
